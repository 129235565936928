import { Component, Inject, OnInit } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from "@angular/material/dialog";

@Component({
	selector: "app-delete-confirmation",
	templateUrl: "./delete-confirmation.component.html",
	styleUrl: "./delete-confirmation.component.css",
})
export class DeleteConfirmationComponent implements OnInit {
	content = "";

	constructor(
		private dialogRef: MatDialogRef<DeleteConfirmationComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.content = this.data.content;
	}

	// ngAfterViewInit(): void {
	// 	this.content = this.data.content;
	// }
	close(value: boolean) {
		this.dialogRef.close(value);
	}
}
