<h2 mat-dialog-title>Confirm</h2>
<div class="icon">
  <img src="../../../../assets/images/close.png" (click)="close(false)" class="close-icon" />
</div>
<mat-dialog-content>
  <p>{{content}}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button (click)="close(false)">Cancel</button>
  <button mat-flat-button color="warn" (click)="close(true)">Confirm</button>
</mat-dialog-actions>